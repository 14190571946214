/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import { UserService } from 'src/app/services/apis/user/user.service';
import { MenuService } from 'src/app/services/useful/menu/menu.service';
import * as moment from 'moment';
import { ProgressBarService } from 'src/app/services/useful/loading/progress-bar/progress-bar.service';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { ModalController, Platform } from '@ionic/angular';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ApoioVendasService } from 'src/app/services/apis/apoio-vendas/apoio-vendas.service';
moment.locale('pt-br');

@Component({
  selector: 'app-file',
  templateUrl: './file.page.html',
  styleUrls: ['./file.page.scss'],
})
export class FilePage {
  @Input('idFile') idFile;
  public init = false;
  // public idFile = '';
  public file = {
    arquivoBase64: '',
    name: '',
    imagemUrl: ''
  };
  public fileBase64 = null;

  constructor(
    private mn: MenuService,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private opener: FileOpener,
    private fl: File,
    private platform: Platform,
    private transfer: FileTransfer,
    private socialSharing: SocialSharing,
    public modalController: ModalController,
    private avService: ApoioVendasService,
  ) { }

  ionViewWillEnter() {
    // this.route.queryParams.subscribe((params) => {
    //   if (params && params.id) {
    //     this.idFile = params.id;
    //     this.GetFile(this.idFile);
    //   }
    // });
    this.GetFile(this.idFile);
    this.mn.menuUpdate();
  }

  ionViewWillLeave() {
    this.init = false;
    this.file = {
      arquivoBase64: '',
      name: '',
      imagemUrl: ''
    };
  }

  GetFile(id) {
    this.avService.GetDocument(id).subscribe(
      (res) => {
        setTimeout(() => {
          if (res.sucesso) {
            this.file = res.document;
          } else {
          }
          this.init = true;
        }, 100);
      },
      (err) => {
        const toast = {
          message: err.error.message,
          color: 'corretor-blue-primary',
          css: 'toastLogin',
          time: 7000,
        };
        this.toast.presentToast(toast);
        this.init = true;
      }
    );
  }

  formatUrl(val) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${val}`
    );
  }

  GoClose() {
    this.modalController.dismiss();
  }

  open() {
    this.saveAndOpenPdf(this.file.imagemUrl, this.file.name);
  }

  download() {
    if (
      this.platform.is('mobileweb') ||
      (this.platform.is('desktop') &&
        !this.platform.is('android') &&
        !this.platform.is('ios'))
    ) {
      let url = this.file.imagemUrl;
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          if (!window.ActiveXObject) {
            let newurl = window.URL.createObjectURL(blob);
            const save = document.createElement('a');
            save.href = newurl;
            save.target = '_blank';
            save.download = this.file.name || 'unknown';
            document.body.appendChild(save);
            this.modalController.dismiss();
            save.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(save);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }

          // // for IE < 11
          else if (!!window.ActiveXObject && document.execCommand) {
            const wwindow = window.open(url, '_blank');
            wwindow.document.close();
            wwindow.document.execCommand(
              'SaveAs',
              true,
              `${this.file.name}` || url
            );
            wwindow.close();
          }

        })


    } else {
      if (this.platform.is('ios') || this.platform.is('android')) {
        let url = this.file.imagemUrl
        const fileTransfer: FileTransferObject = this.transfer.create();
        const writeDirectory = this.platform.is('ios')
          ? this.fl.dataDirectory
          : this.fl.externalDataDirectory;
        fileTransfer.download(url, writeDirectory + this.file.name).then(
          (entry) => {
            console.log('download complete: ' + entry.toURL());
            this.opener
              .open(entry.toURL(), 'application/pdf')
              .then(() => console.log('File is opened'))
              .catch((e) => console.log('Error openening file'));
          },
          (error) => {
            // handle error
            console.log(error);
          }
        );
      } else {
        let url = this.file.imagemUrl
        if (!window.ActiveXObject) {
          const save = document.createElement('a');
          save.href = url;
          save.target = '_blank';
          save.download = this.file.name || 'unknown';
          this.modalController.dismiss();
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }

        // // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
          const wwindow = window.open(url, '_blank');
          wwindow.document.close();
          wwindow.document.execCommand(
            'SaveAs',
            true,
            `${this.file.name}` || url
          );
          wwindow.close();
        }
      }
    }
  }

  async share() {
    this.progressBar.startProgressBar();
    if (
      this.platform.is('mobileweb') ||
      (this.platform.is('desktop') &&
        !this.platform.is('android') &&
        !this.platform.is('ios'))
    ) {
      const link = this.file.imagemUrl;
      this.progressBar.stopProgressBar();
      window.open(
        `https://api.whatsapp.com/send?text= ${this.file.name
        } - ${link}`,
        '_blank'
      );
    } else {
      const msg = `Compartilhe o arquivo com o aplicativo desejado`;
      const fileTransfer: FileTransferObject = this.transfer.create();
      const writeDirectory = this.platform.is('ios')
        ? this.fl.dataDirectory
        : this.fl.externalDataDirectory;
      fileTransfer.download(this.file.imagemUrl, writeDirectory + this.file.name).then(
        (entry) => {
          console.log(entry)
          this.progressBar.stopProgressBar();
          this.socialSharing
            .share(msg, this.file.name, entry.nativeURL, null)
            .then(() => { })
            .catch(() => { });
        },
        (error) => {
          // handle error
          this.progressBar.stopProgressBar();
          console.log(error);
        })

    }
    // end mobile
  }

  saveAndOpenPdf(pdf: string, filename: string) {
    this.progressBar.startProgressBar();
    if (
      this.platform.is('mobileweb') ||
      (this.platform.is('desktop') &&
        !this.platform.is('android') &&
        !this.platform.is('ios'))
    ) {
      // init webmobile and desktop
      this.progressBar.stopProgressBar();
      window.open(pdf, '_blank');
      // end webmobile and desktop
    } else {
      // init mobile
      let url = pdf
      const fileTransfer: FileTransferObject = this.transfer.create();
      const writeDirectory = this.platform.is('ios')
        ? this.fl.dataDirectory
        : this.fl.externalDataDirectory;
      fileTransfer.download(url, writeDirectory + this.file.name).then(
        (entry) => {
          console.log('download complete: ' + entry.toURL());
          this.progressBar.stopProgressBar();
          this.opener
            .open(entry.toURL(), 'application/pdf')
            .then(() => {
              this.progressBar.stopProgressBar();
              console.log('File is opened')
            })
            .catch((e) => {
              this.progressBar.stopProgressBar();
              console.log('Error openening file')
            });
        },
        (error) => {
          // handle error
          this.progressBar.stopProgressBar();
          console.log(error);
        }
      )
      // end mobile
    }
  }

  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
