/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable object-shorthand */
/* eslint-disable one-var */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import {
  AlertController,
  AnimationController,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { HeaderColor } from '@ionic-native/header-color/ngx';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';
import { StatusBar } from '@ionic-native/status-bar';

import { AuthenticationService } from './services/apis/authentication/authentication.service';
import { ToastService } from './services/useful/toast/toast.service';
import { UserService } from './services/apis/user/user.service';
import { environment } from 'src/environments/environment';
import { ProgressBarService } from './services/useful/loading/progress-bar/progress-bar.service';
import * as $ from 'jquery';
import { ModalBoletoPage } from './pages/user/modal/modal-boleto/modal-boleto.page';
import { ModalNotificationPage } from './pages/user/modal/modal-notification/modal-notification.page';
import { VersionService } from './services/apis/version/version.service';

declare global {
  interface Window {
    ActiveXObject: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private logo: any = 'assets/logo/Logo_EasyPlan.png';
  user: any;
  menuValue: any = 'dashboard';
  isCPF = false;
  public ir: any = [];
  public notification = [];
  public timeout: any;
  public hash: any

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private headerColor: HeaderColor,
    private authenticationService: AuthenticationService,
    private router: Router,
    private menu: MenuController,
    private toast: ToastService,
    public alertController: AlertController,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private iab: InAppBrowser,
    private headerColor: HeaderColor,
    public modalController: ModalController,
    public animationCtrl: AnimationController,
    private versionServer: VersionService,
  ) {
    StatusBar.hide()
    this.hash = location.hash
    this.InitializeApp();
  }

  InitializeApp() {
    this.platform.ready().then(() => {
      this.headerColor.tint('#00a7ba');
      // this.splashScreen.hide();
      this.menu.swipeGesture(false);
      // this.Authentication();
      this.AuthenticationUser();
      this.versionServer.CheckUpdate();
      setTimeout(() => {
        StatusBar.backgroundColorByHexString('#00a7ba');
        StatusBar.styleLightContent();
      }, 2000);
    });
  }

  async Authentication() {
    this.authenticationService.authenticationState.subscribe(async (state) => {
      if (state) {
        console.log(location.hash.slice(0, location.hash.indexOf('?')))
        if (location.hash.slice(0, location.hash.indexOf('?') + 1) === '#/authentication' || location.hash.slice(0, location.hash.indexOf('?') + 1) === '#/shorten') {

        }
        else {
          this.router.navigate(['authentication']);
        }
      }
    });
  }

  async AuthenticationUser() {
    this.authenticationService.authenticationStateUser.subscribe(
      async (state) => {
        if (state) {
          this.user = await JSON.parse(
            !this.platform.is('android') && !this.platform.is('ios')
              ? environment.storageWeb.getItem('USER')
              : environment.storageMobile.getItem('USER')
          );
          if (!this.platform.is('android') && !this.platform.is('ios')) {
            this.setTimeout();
          }
          this.GetUserNotification();
          this.ValidCPFCNPJ();
          let navigationExtras: NavigationExtras = {
            queryParams: {
              hash: this.hash,
            },
            queryParamsHandling: this.hash ? 'merge' : 'preserve',
          };
          this.router.navigate(['presentation'], navigationExtras);
          this.hash = '';
          $(`#${this.menuValue}`).addClass('active');
        }
      }
    );
  }

  setTimeout() {
    this.timeout = setTimeout(() => {
      if (location.hash === '#/user/create-files') {
        clearTimeout(this.timeout);
        this.setTimeout();
      } else {
        this.modalController.dismiss();
        this.authenticationService.Logout();
        this.modalController.dismiss();
        const toast = {
          message: 'Sua sessão expirou. Faça login para acessar novamente!',
          color: 'warning',
        };
        this.toast.presentToastWithOptions(toast);
      }
    }, environment.expires);
  }

  CloseMenu() {
    this.menu.close('left');
  }

  navegatePage(page) {
    if (page) {
      this.menuValue = page;
      $('.itemMenu').removeClass('active');
      $(`#${page}`).addClass('active');
      switch (page) {
        case 'dashboard':
          this.router.navigate(['user', 'dashboard']);
          break;
        case 'sales':
          this.router.navigate(['user', 'sales']);
          break;

        case 'commissions':
          this.router.navigate(['user', 'commissions']);
          break;

        case 'dados':
          this.router.navigate(['user', 'your-data']);
          break;
        case 'doubts':
          this.router.navigate(['user', 'doubts']);
          break;
        case 'support':
          this.router.navigate(['user', 'sales-support']);
          break;
        case 'sell':
          this.router.navigate(['user', 'sell']);
          break;
        case 'bonuses':
          this.router.navigate(['user', 'bonuses']);
          break;
        case 'commission-report':
          this.router.navigate(['user', 'commission-report']);
          break;
        case 'bonus-rule':
          this.router.navigate(['user', 'bonus-rule']);
          break;
        case 'validity-and-closing':
          this.router.navigate(['user', 'validity-and-closing']);
          break;
        case 'contact':
          this.router.navigate(['user', 'contact']);
          break;
        case 'price':
          this.router.navigate(['user', 'price']);
          break;

        default:
          break;
      }
      this.menu.close('left');
    }
  }

  async DoLogout() {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: 'Você realmente deseja <strong>sair</strong>?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            this.CloseMenu();
          },
        },
        {
          text: 'Sair',
          handler: () => {
            this.menu.close('left');
            clearTimeout(this.timeout);
            this.authenticationService.Logout();
          },
        },
      ],
    });

    await alert.present();
  }

  async ValidCPFCNPJ() {
    this.user = await JSON.parse(
      !this.platform.is('android') && !this.platform.is('ios')
        ? environment.storageWeb.getItem('USER')
        : environment.storageMobile.getItem('USER')
    );
    if (this.user.taxvat.length === 11) {
      this.isCPF = true;
    } else {
      this.isCPF = false;
    }
    console.log(this.isCPF);
    // this.userService.GetUserInfo().subscribe(
    //   (res) => {
    //     setTimeout(async () => {
    //       // res = environment.userMock;
    //       if (res.sucesso === true) {
    //         if (res.modelo.titular.Data.cpf.length === 11) {
    //           this.isCPF = true;
    //         } else {
    //           this.isCPF = false;
    //         }
    //       }
    //     }, 100);
    //   },
    //   (error) => {
    //     this.progressBar.stopProgressBar();
    //   }
    // );
  }

  // async GetIRUser() {
  //   this.progressBar.startProgressBar();
  //   this.userService.GetUserInfo().subscribe(
  //     (res) => {
  //       setTimeout(async () => {
  //         // res = environment.userMock;
  //         if (res.sucesso === true) {
  //           if (this.isCPF === true) {
  //             if (res.urlIR.length > 0) {
  //               await res.urlIR.forEach((element) => {
  //                 this.ir.push({
  //                   name: element.ano,
  //                   type: 'radio',
  //                   label: element.ano,
  //                   value: element.url,
  //                 });
  //               });
  //               this.progressBar.stopProgressBar();
  //               this.menu.close('left');
  //               this.presentAlertIR(this.ir);
  //             } else {
  //               this.progressBar.stopProgressBar();
  //               this.menu.close('left');
  //               this.presentAlertNOIR();
  //             }
  //           } else {
  //             this.progressBar.stopProgressBar();
  //           }
  //         }
  //       }, 100);
  //     },
  //     (error) => {
  //       this.progressBar.stopProgressBar();
  //     }
  //   );
  // }

  async presentAlertIR(ir) {
    const alert = await this.alertController.create({
      header: 'IR (Imposto de Renda)',
      message:
        'Selecione o ano no qual você deseja fazer o downloado do seu IR.',
      inputs: ir,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            this.ir = [];
          },
        },
        {
          text: 'Download',
          cssClass: 'irDownload',
          handler: async (data) => {
            if (data) {
              if (this.platform.is('android')) {
                const browser = await this.iab.create(
                  data,
                  '_blank',
                  'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
                );
                browser.show();
                this.ir = [];
              } else if (this.platform.is('ios')) {
                const browser = await this.iab.create(
                  data,
                  '_blank',
                  'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
                );
                browser.show();
                this.ir = [];
              } else {
                if (!window.ActiveXObject) {
                  const save = document.createElement('a');
                  save.href = data;
                  save.target = '_blank';
                  save.download = 'IR' || 'unknown';

                  const evt = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                  });
                  save.dispatchEvent(evt);

                  (window.URL || window.webkitURL).revokeObjectURL(save.href);
                  this.ir = [];
                }

                // for IE < 11
                else if (!!window.ActiveXObject && document.execCommand) {
                  const wwindow = window.open(data, '_blank');
                  wwindow.document.close();
                  wwindow.document.execCommand('SaveAs', true, 'IR' || data);
                  wwindow.close();
                  this.ir = [];
                }
              }
            } else {
              this.ir = [];
            }
          },
        },
        {
          text: '',
          cssClass: 'irWhatsApp',
          handler: async (data) => {
            if (data) {
              if (this.platform.is('android')) {
                const browser = await this.iab.create(
                  `https://api.whatsapp.com/send?text=Acesse o IR: - ${data}`,
                  '_blank',
                  'location=yes,closebuttoncolor=#ffffff,hidenavigationbuttons=yes,hideurlbar=yes,Navigationbuttoncolor=#3c6070,toolbarcolor=#3c6070'
                );
                browser.show();
                this.ir = [];
              } else if (this.platform.is('ios')) {
                const browser = await this.iab.create(
                  `https://api.whatsapp.com/send?text=Acesse o IR: - ${data}`,
                  '_blank',
                  'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
                );
                browser.show();
                this.ir = [];
              } else {
                window.open(
                  `https://api.whatsapp.com/send?text=Acesse o IR: - ${data}`,
                  '_blank'
                );
              }
            } else {
              this.ir = [];
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async presentAlertNOIR() {
    const alert = await this.alertController.create({
      header: 'IR (Imposto de Renda)',
      message: 'Usuário não possui IR para download',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async GoModalNotification() {
    this.menu.close('left');
    if (this.notification.length > 0) {
      const enterAnimation = (baseEl: any) => {
        const wrapperAnimation = this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'scale(0)' },
            { offset: 1, opacity: '1', transform: 'scale(1)' },
          ]);

        return this.animationCtrl
          .create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(200)
          .addAnimation([wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: any) =>
        enterAnimation(baseEl).direction('reverse');

      const modal = await this.modalController.create({
        component: ModalNotificationPage,
        componentProps: {
          notification: this.notification,
        },
        cssClass: 'classModalNotification',
        enterAnimation,
        leaveAnimation,
        // backdropDismiss: false,
      });

      modal.onWillDismiss().then((ev) => {
        if (ev.data.boleto) {
          // this.GoModalBoleto(ev.data.boleto);
        } else {
        }
      });

      return await modal.present();
    } else {
      this.presentAlertNONotification();
    }
  }

  async presentAlertNONotification() {
    const alert = await this.alertController.create({
      header: 'Notificação',
      message: 'Usuário não possui notificação',
      buttons: ['OK'],
    });

    await alert.present();
  }

  GetUserNotification() {
    // this.userService.GetUserInfo().subscribe(
    //   (res) => {
    //     setTimeout(() => {
    //       // res = environment.userMock;
    //       if (res.sucesso === true) {
    //         res.modelo.parcelas.forEach((element) => {
    //           if (
    //             element.situacao === 'Aguardando Liquidação' ||
    //             element.situacao === 'Vencida'
    //           ) {
    //             this.notification.push(element);
    //           }
    //         });
    //       } else {
    //       }
    //     }, 100);
    //   },
    //   (error) => {}
    // );
  }
}
