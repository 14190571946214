/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-input-rename */
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as $ from 'jquery';
import { CommissionsService } from 'src/app/services/apis/commissions/commissions.service';
import { UserService } from 'src/app/services/apis/user/user.service';
import { ProgressBarService } from 'src/app/services/useful/loading/progress-bar/progress-bar.service';
import { ToastService } from 'src/app/services/useful/toast/toast.service';

@Component({
  selector: 'app-modal-upload-nf',
  templateUrl: './modal-upload-nf.page.html',
  styleUrls: ['./modal-upload-nf.page.scss'],
})
export class ModalUploadNfPage {
  @Input('nf') nf;
  @Input('report') report;
  public valorLiquido: any = null;
  public dataEmissao: any = null;
  public numeroNF: any = null;

  constructor(
    public modalController: ModalController,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private toast: ToastService,
    private coService: CommissionsService,
  ) { }

  async ionViewWillEnter() {
    $('#content').append(
      // `<iframe src="${this.nf.link}" name="frame_pdf" id="frame_pdf" frameborder="0" allowtransparency="true"></iframe>`
      `
      <object data="${this.nf.link}#toolbar=0" type="application/pdf" width="100%" height="100%">
        <ion-img src="assets/icon/file-document.svg" style="width:50%; margin: auto; padding-top: 50%;"></ion-img>
      </object>
      `
    );
  }

  GoClose() {
    this.modalController.dismiss({
      dismissed: 'cancel',
    });
  }

  GoUploado() {
    // console.log(this.valorLiquido.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '.'));
    // console.log(this.dataEmissao)
    // console.log(this.numeroNF)
    if (this.valorLiquido && this.dataEmissao && this.numeroNF) {
      this.progressBar.startProgressBar();
      this.coService.UploadNFSubLote(
        this.nf,
        this.report.id,
        this.valorLiquido.replace('R$', '').replace('.', '').replace(',', '.').replace(' ', '.'),
        this.dataEmissao,
        this.numeroNF
      ).subscribe(
        (res) => {
          if (res.sucesso) {
            setTimeout(async () => {
              const toast = {
                message: res.mensagem,
                color: 'corretor-blue-primary',
                css: 'toastLogin',
                duration: 7000,
              };
              this.progressBar.stopProgressBar();
              this.toast.presentToast(toast);
              this.modalController.dismiss({
                dismissed: 'success',
              });
            }, 100);
          }
          else {
            const toast = {
              message: 'Não foi possível enviar o Documento!',
              color: 'corretor-blue-primary',
              css: 'toastLogin',
              duration: 7000,
            };
            this.progressBar.stopProgressBar();
            this.toast.presentToast(toast);
            this.modalController.dismiss({
              dismissed: 'success',
            });
          }
        },
        (err) => {
          console.log(err)
          const toast = {
            message: err.error.message,
            color: 'corretor-blue-primary',
            css: 'toastLogin',
            duration: 7000,
          };
          this.progressBar.stopProgressBar();
          this.toast.presentToast(toast);
        }
      );
    }
    else {
      const toast = {
        message: 'Todos os campos são obrigatórios, verifique!',
        color: 'warning',
        css: 'toastLogin',
        duration: 4000,
      };
      this.toast.presentToast(toast);
    }
  }

  FormatDate(ev) {
    let value = ev.detail.value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(-\d{4})\d+?$/, '$1');
    ev.target.value = value;
  }

  FormatNumero(ev) {
    let value = ev.detail.value
      .replace(/\D/g, '')
      .replace(/[^0-9]/, '')
    ev.target.value = value;
  }

  mascaraMoeda(event) {
    const onlyDigits = event.target.value
      .split("")
      .filter(s => /\d/.test(s))
      .join("")
      .padStart(3, "0")
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2)
    event.target.value = this.maskCurrency(digitsFloat)
  }

  maskCurrency(valor, locale = 'pt-BR', currency = 'BRL') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency
    }).format(valor)
  }
}
