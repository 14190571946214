import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor() { }

  menuUpdate() {
    $('.itemMenu').removeClass('active');
    switch (location.hash) {
      case '#/user/dashboard':
        $(`#dashboard`).addClass('active');
        break;
      case '#/user/your-data':
        $(`#dados`).addClass('active');
        break;
      case '#/user/commissions':
        $(`#commissions`).addClass('active');
        break;
      case '#/user/sales':
        $(`#sales`).addClass('active');
        break;
      case '#/user/doubts':
        $(`#doubts`).addClass('active');
        break;
      case '#/user/sales-support':
        $(`#support`).addClass('active');
        break;
      case '#/user/sell':
        $(`#sell`).addClass('active');
        break;
      case '#/user/bonuses':
        $(`#bonuses`).addClass('active');
        break;
      case '#/user/commission-report':
        $(`#commission-report`).addClass('active');
        break;
      case '#/user/bonus-rule':
        $(`#bonus-rule`).addClass('active');
        break;
      case '#/user/validity-and-closing':
        $(`#validity-and-closing`).addClass('active');
        break;
      case '#/user/contact':
        $(`#vcontact`).addClass('active');
        break;
      case '#/user/price':
        $(`#price`).addClass('active');
        break;
      case '#/user/campaigns':
        $(`#campaigns`).addClass('active');
        break;
      default:
        break;
    }
  }
}
