/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import * as moment from 'moment';

import { User } from 'src/app/auth/user';
import { AuthResponse } from 'src/app/auth/authResponse';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(true);
  authenticationStateUser = new BehaviorSubject(false);

  constructor(
    private plt: Platform,
    private platform: Platform,
    private httpClient: HttpClient,
    private toast: ToastService,
    private router: Router,
  ) {
    this.plt.ready().then(() => {
      this.CheckToken();
    });
  }

  async CheckToken() {
    if (!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER')) {
      let user = await JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
      switch (user.sucesso) {
        case true:
          this.authenticationStateUser.next(true);
          this.authenticationState.next(false);
          break;
        default:
          this.authenticationStateUser.next(false);
          this.authenticationState.next(true);
      }
    }
  }

  IsAuthenticated() {
    // return this.authenticationState.asObservable();
    return this.authenticationState.value;
  }

  IsUserAuthenticated() {
    return this.authenticationStateUser.value;
  }

  Login(user): Observable<AuthResponse> {
    // let base64 = btoa(`${user.cpf.replace(/\D/g, '')}:${user.password}`);
    // let base64 = btoa(`Basic 65617398091:teste`);
    // let data = `Basic ${base64}`;
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     Authorization: environment.Authorization,
    //     // SalerAuth: 'Basic MDM0MTI3NjIxOTk6MTIzNDU2',
    //     SalerAuth: data,
    //   }),
    // };

    let formData = new FormData();
    if (user.cpf.replace(/\D/g, '').length === 11) {
      formData.append('cpf', user.cpf.replace(/\D/g, ''));
      formData.append('senha', user.password);

      return (
        this.httpClient
          // .get(`${environment.AUTH_SERVER_ADDRESS}/SalerAuth`, httpOptions)
          .post(
            `${environment.AUTH_SERVER_ADDRESS_NEW}/AutenticarComSenha`,
            formData
          )
          .pipe(
            tap(async (res: AuthResponse) => {
              if (res.sucesso) {
                res.sucesso = true;
                res.taxvat = user.cpf.replace(/\D/g, '');
                // await environment.storage.setItem('USER', JSON.stringify(res));
                if (!this.platform.is('android') && !this.platform.is('ios')) {
                  await environment.storageWeb.setItem(
                    'USER',
                    JSON.stringify(res)
                  );
                } else {
                  environment.storageMobile.setItem(
                    'USER',
                    JSON.stringify(res)
                  );
                }
                setTimeout(() => {
                  if (res) {
                    this.authenticationStateUser.next(true);
                    this.authenticationState.next(false);
                  } else {
                    this.authenticationStateUser.next(false);
                    this.authenticationState.next(true);
                  }
                }, 750);
              }
            })
          )
      );
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: environment.pandadoc.Key,
          'Content-Type': 'application/json',
        }),
      };

      return (
        this.httpClient
          .post(
            `${environment.SERVER_ADDRESS}corretora/auth/signin`,
            {
              cnpj: user.cpf.replace(/\D/g, ''), password: user.password
            },
            httpOptions
          )
          .pipe(
            tap(async (res: AuthResponse) => {
              if (res.sucesso) {
                res.sucesso = true;
                res.taxvat = user.cpf.replace(/\D/g, '');
                if (!this.platform.is('android') && !this.platform.is('ios')) {
                  await environment.storageWeb.setItem(
                    'USER',
                    JSON.stringify(res)
                  );
                } else {
                  environment.storageMobile.setItem(
                    'USER',
                    JSON.stringify(res)
                  );
                }
                setTimeout(() => {
                  if (res) {
                    this.authenticationStateUser.next(true);
                    this.authenticationState.next(false);
                  } else {
                    this.authenticationStateUser.next(false);
                    this.authenticationState.next(true);
                  }
                }, 750);
              }
            })
          )
      );
    }
  }

  Register(form): Observable<any> {
    let formData = new FormData();

    formData.append('nome', form.name);
    formData.append('cpfCnpj', form.cpfcnpj.replace(/\D/g, ''));
    formData.append('contato.nome', form.name);
    formData.append('contato.email', form.email);
    formData.append('contato.telefone', form.phone.replace(/\D/g, ''));
    formData.append(
      'contato.telefoneAlternativo',
      form.phone.replace(/\D/g, '')
    );
    // formData.append('endereco.logradouro', form.address);
    // formData.append('endereco.numero', form.number);
    // formData.append('endereco.bairro', form.district);
    // formData.append('endereco.complemento', form.complemento);
    formData.append('endereco.cidadeID', form.city);
    // formData.append('endereco.CEP', form.postcode.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');
    formData.append('senha', form.password);
    formData.append(
      'responsavel_conta',
      form.responsavel_conta ? form.responsavel_conta : ''
    );
    formData.append(
      'cpf_cnpj_responsavel',
      form.cpf_cnpj_responsavel
        ? form.cpf_cnpj_responsavel.replace(/\D/g, '')
        : ''
    );

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterBank(form, produtorID): Observable<any> {
    let formData = new FormData();
    formData.append('produtorID', produtorID);
    formData.append('bancoID', form.banco);
    formData.append('agencia', form.agencia);
    formData.append('agenciaDigito', form.dv_agencia ? form.dv_agencia : '');
    formData.append('conta', form.conta_corrente);
    formData.append('contaDigito', form.dv_conta_corrente);
    formData.append('tipoContaCorrenteID', form.tipo_conta);
    formData.append('responsavel', form.name);
    formData.append('cpfCnpj', form.cpfcnpj.replace(/\D/g, ''));
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/ProdutorConta/Salvar`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterEquipe(form, produtorID): Observable<any> {
    let formData = new FormData();
    formData.append('corretoraID', form.corretora);
    formData.append('estadoID', form.state_corretora);
    formData.append('produtorID', produtorID);
    formData.append('supervisorID', form.supervisor ? form.supervisor : '');
    formData.append('supervisor', form.is_supervisor);

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/VincularEquipe`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  RegisterLogin(user): Observable<AuthResponse> {
    let formData = new FormData();
    if (user.document.replace(/\D/g, '').length === 11) {
      formData.append('cpf', user.document.replace(/\D/g, ''));
    } else {
      formData.append('cpf', user.document.replace(/\D/g, ''));
    }
    formData.append('senha', user.password);

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/AutenticarComSenha`,
        formData
      )
      .pipe(tap(async (res: AuthResponse) => { }));
  }

  Reset(form, code): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .put(
        `${environment.AUTH_SERVER_ADDRESS}/Saler/${code}`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetVendedor(cod): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(`${environment.AUTH_SERVER_ADDRESS}/Saler/${cod}`, httpOptions)
      .pipe(
        tap(async (res: User) => {
          if (res) {
            res.sucesso = true;
            // await environment.storage.setItem('USER', JSON.stringify(res));
            if (!this.platform.is('android') && !this.platform.is('ios')) {
              await environment.storageWeb.setItem(
                'USER',
                JSON.stringify(res)
              );
            } else {
              environment.storageMobile.setItem(
                'USER',
                JSON.stringify(res)
              );
            }
          }
        })
      );
  }

  GetValidVendedor(form): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.Authorization,
      }),
    };

    return this.httpClient
      .get(
        `${environment.AUTH_SERVER_ADDRESS}/Saler/?taxvat=${form.taxvat}&email=${form.email}`,
        httpOptions
      )
      .pipe(tap(async (res: User) => { }));
  }

  async PostDevice(device) {
    let user = await JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let formData = new FormData();
    formData.append('token', device.token);
    formData.append('tipo', device.tipo);
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/UsuarioToken/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  async Logout() {
    // environment.storage.removeItem('USER');
    if (!this.platform.is('android') && !this.platform.is('ios')) {
      await environment.storageWeb.removeItem('USER');
    } else {
      await environment.storageMobile.removeItem('USER');
    }
    this.authenticationState.next(true);
    this.authenticationStateUser.next(false);
    this.router.navigate(['auth']);
  }

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('Um erro ocorreu:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // console.error(
      //   `Código retornado pelo backend ${error.status}, ` +
      //   `corpo era: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Algo de errado aconteceu; Por favor, tente novamente mais tarde!'
    );
  }

  updateUser(user): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateEquipe(user, stateId): Observable<any> {
    let formData = new FormData();
    formData.append(
      'corretoraID',
      user.equipes.length > 0 ? user.equipes[0].corretora.corretoraID : ''
    );
    formData.append('estadoID', stateId);
    formData.append('produtorID', user.produtorID);
    formData.append(
      'supervisorID',
      user.equipes.length > 0 ? user.equipes[0].corretora.supervisor : ''
    );

    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaCorretor/VincularEquipe`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateEndereco(user): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updatePassword(user, password): Observable<any> {
    let formData = new FormData();

    formData.append('produtorID', user.produtorID);
    formData.append('nome', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    formData.append(
      'contato.nome',
      user.contatos.length > 0 ? user.contatos[0].nome : ''
    );
    formData.append(
      'contato.email',
      user.contatos.length > 0 ? user.contatos[0].email : ''
    );
    formData.append(
      'contato.telefone',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'contato.telefoneAlternativo',
      user.contatos.length > 0
        ? user.contatos[0].telefone.replace(/\D/g, '')
        : ''
    );
    formData.append(
      'enderecoID',
      user.enderecos.length > 0 ? user.enderecos[0].enderecoID : ''
    );
    // formData.append('endereco.logradouro', user.enderecos[0].logradouro);
    // formData.append(
    //   'endereco.numero',
    //   user.enderecos[0].numero === 'null' ? null : user.enderecos[0].numero
    // );
    // formData.append('endereco.bairro', user.enderecos[0].bairro);
    // formData.append(
    //   'endereco.complemento',
    //   user.enderecos[0].complemento === 'null'
    //     ? null
    //     : user.enderecos[0].complemento
    // );
    formData.append(
      'endereco.cidadeID',
      user.enderecos.length > 0 ? user.enderecos[0].cidadeID : ''
    );
    // formData.append('endereco.CEP', user.enderecos[0].CEP.replace(/\D/g, ''));
    formData.append('endereco.fiscal', 'true');
    formData.append('endereco.principal', 'true');
    formData.append('senha', password);

    return this.httpClient
      .post(
        `${environment.AUTH_SERVER_ADDRESS_NEW}/CadastrarProdutorCompleto`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  updateBank(user): Observable<any> {
    let formData = new FormData();
    formData.append(
      'produtorContaID',
      user.contas.length > 0 ? user.contas[0].produtorContaID : ''
    );
    formData.append('produtorID', user.produtorID);
    formData.append(
      'bancoID',
      user.contas.length > 0 ? user.contas[0].bancoID : ''
    );
    formData.append(
      'agencia',
      user.contas.length > 0 ? user.contas[0].agencia : ''
    );
    formData.append(
      'agenciaDigito',
      user.contas.length > 0 ? user.contas[0].agenciaDigito : ''
    );
    formData.append(
      'conta',
      user.contas.length > 0 ? user.contas[0].conta : ''
    );
    formData.append(
      'contaDigito',
      user.contas.length > 0 ? user.contas[0].contaDigito : ''
    );
    formData.append(
      'tipoContaCorrenteID',
      user.contas.length > 0 ? user.contas[0].tipoContaCorrenteID : ''
    );
    formData.append('responsavel', user.nome);
    formData.append('cpfCnpj', user.cpfCnpj.replace(/\D/g, ''));
    const httpOptions = {
      withCredentials: true,
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/ProdutorConta/Salvar`,
        formData
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateDocument(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.pandadoc.url}`,
        {
          name: form.dados.razao_social,
          folder_uuid: 's2UxpAnCDpGi5UaG4SALXg',
          // template_uuid: 'cvBQJvZ2wa33rAjJDobDp2',
          template_uuid: 'qMQRHj32KKhzhgPb9XxJD3',
          recipients: [
            {
              email: form.dados.corretoras_contatos[0].email,
              first_name: form.dados.corretoras_responsavels[0].nome.slice(0, form.dados.corretoras_responsavels[0].nome.indexOf(' ')),
              last_name: form.dados.corretoras_responsavels[0].nome.slice(
                form.dados.corretoras_responsavels[0].nome.indexOf(' '),
                form.dados.corretoras_responsavels[0].nome.length + 1
              ),
              role: 'Contratada',
            },
          ],
          fields: {
            razaosocial: {
              value: form.dados.razao_social,
            },
            cep: {
              value: form.dados.corretoras_enderecos[0].cep,
            },
            cidade_estado: {
              value: `${form.cidade.cidadeNome} / ${form.estado.estadoUF}`,
            },
            cnpj: {
              value: form.dados.cnpj
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1/$2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
            },
            cpf_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_CPF
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1'),
            },
            endereco_completo: {
              value: `${form.dados.corretoras_enderecos[0].rua}, ${form.dados.corretoras_enderecos[0].numero}, ${form.dados.corretoras_enderecos[0].bairro}, ${form.dados.corretoras_enderecos[0].complemento}`,
            },
            nome_responsavel: {
              value: form.dados.corretoras_responsavels[0].nome,
            },
            rg_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_RG,
            },
            ssp_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_SSP,
            }
          },
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateTermoAditivo(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.pandadoc.url}`,
        {
          name: form.dados.razao_social,
          folder_uuid: 's2UxpAnCDpGi5UaG4SALXg',
          // template_uuid: 'cvBQJvZ2wa33rAjJDobDp2',
          template_uuid: 'GyX6RdWjK73wE8tZDXAW5V',
          recipients: [
            {
              email: form.dados.corretoras_contatos[0].email,
              first_name: form.dados.corretoras_responsavels[0].nome.slice(0, form.dados.corretoras_responsavels[0].nome.indexOf(' ')),
              last_name: form.dados.corretoras_responsavels[0].nome.slice(
                form.dados.corretoras_responsavels[0].nome.indexOf(' '),
                form.dados.corretoras_responsavels[0].nome.length + 1
              ),
              role: 'Contratada',
            },
          ],
          fields: {
            razaosocial: {
              value: form.dados.razao_social,
            },
            cep: {
              value: form.dados.corretoras_enderecos[0].cep,
            },
            cidade_estado: {
              value: `${form.cidade.cidadeNome} / ${form.estado.estadoUF}`,
            },
            cnpj: {
              value: form.dados.cnpj
                .replace(/\D/g, '')
                .replace(/(\d{2})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1/$2')
                .replace(/(\d{4})(\d)/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
            },
            cpf_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_CPF
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1'),
            },
            endereco_completo: {
              value: `${form.dados.corretoras_enderecos[0].rua}, ${form.dados.corretoras_enderecos[0].numero}, ${form.dados.corretoras_enderecos[0].bairro}, ${form.dados.corretoras_enderecos[0].complemento}`,
            },
            nome_responsavel: {
              value: form.dados.corretoras_responsavels[0].nome,
            },
            rg_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_RG,
            },
            ssp_responsavel: {
              value: form.dados.corretoras_responsavels[0].documento_SSP,
            }
          },
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DocumentStatus(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get(`${environment.pandadoc.url}/${documentID}`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  SendDocument(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.pandadoc.url}/${documentID}/send`,
        {
          subject: 'CONTRATOS EASYPLAN ADMINISTRADORA',
          message: `
          Olá, parceiro (a)!

Estamos entrando em contato para informar que, devido a novas atualizações na comercialização dos nossos produtos, precisamos solicitar a realização da assinatura digital dos nossos contratos.

OBS: O responsável legal quem deve assinar. 

Agradecemos pela sua confiança em nossa empresa e esperamos continuar proporcionando os melhores benefícios para você e sua equipe.

Fique à vontade para entrar em contato conosco para esclarecer quaisquer dúvidas.
          `,
          silent: false,
          forwarding_settings: {
            forwarding_allowed: true,
            forwarding_with_reassigning_allowed: true,
          },
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DeleteDocument(documentID) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: environment.pandadoc.Key,
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .delete(`${environment.pandadoc.url}/${documentID}/`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  SendWhatApp(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/twofactorauthentication`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  VerifyWhatApp(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/twofactorauthentication/verifywhatsapp`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  SendWhatAppDocument(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': form.accessToken,
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}whatsapp/corretora/sendmessagelinkdocument`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetShorten(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}shorten/${id}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetVerifyWhatsApp(cnpj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/twofactorauthentication/${cnpj}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateContato(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/contato`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateEndereco(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/endereco`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateResponsavel(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/responsavel`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateDadosAcesso(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/acesso`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateDadosBancarios(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/bancario`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  CreateCorretora(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/`,
        form,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateCorretoraSituacao(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': form.accessToken,
      }),
    };

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/${form.id}`,
        { situacao_ID: form.situacao_ID },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateCorretoraTermoAditivoS(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': form.accessToken,
      }),
    };

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/${form.id}`,
        { termo_aditivo: form.termo_aditivo },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateCorretoraContrato(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': form.accessToken,
      }),
    };

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/${form.id}`,
        {
          contrato_ID: form.contrato_ID,
          contrato_URL: form.contrato_URL,
          termo_aditivo_ID: form.termo_aditivo_ID ? form.termo_aditivo_ID : form.contrato_ID,
          termo_aditivo_URL: form.termo_aditivo_URL ? form.termo_aditivo_URL : form.contrato_URL,
          termo_aditivo: form.termo_aditivo ? form.termo_aditivo : true,
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  UpdateCorretoraTermoAditivo(form) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': form.accessToken,
      }),
    };

    return this.httpClient
      .put(
        `${environment.SERVER_ADDRESS}corretora/${form.id}`,
        {
          termo_aditivo_ID: form.termo_aditivo_ID,
          termo_aditivo_URL: form.termo_aditivo_URL,
          termo_aditivo: form.termo_aditivo,
        },
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  AddDocumentoCorretora(doc): Observable<any> {
    let formData = new FormData();
    formData.append('file', doc.file);

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/documento`,
        formData,
      )
      .pipe(tap(async (res: any) => { }));
  }

  AddDocumentoResponsavel(doc): Observable<any> {
    let formData = new FormData();
    formData.append('file', doc.file);

    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/responsavel/documento`,
        formData,
      )
      .pipe(tap(async (res: any) => { }));
  }
}
