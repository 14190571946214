import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class ProgressBarService {
  constructor() {}

  public startProgressBar() {
    $('body')
      .append(
        `
        <ion-backdrop id="backdrop" visible="false" tappable="false" stopPropagation="false"></ion-backdrop>
        <ion-progress-bar id="progress-bar" type="indeterminate" color="corretor-green-primary"></ion-progress-bar>
      `
      )
      .show('slow');
  }

  public stopProgressBar() {
    $('body #progress-bar').remove();
    $('body #backdrop').remove();
  }

  public startProgressBarTransparent() {
    $('body')
      .append(
        `
        <ion-backdrop id="backdropTransparent" visible="false" tappable="false" stopPropagation="false"></ion-backdrop>
        <ion-progress-bar id="progress-bar" type="indeterminate" color="corretor-green-primary"></ion-progress-bar>
      `
      )
      .show('slow');
  }

  public stopProgressBarTransparent() {
    $('body #progress-bar').remove();
    $('body #backdropTransparent').remove();
  }

  public startProgressBarExport() {
    $('body')
      .append(
        `
        <ion-backdrop id="backdropExport" visible="false" tappable="false" stopPropagation="false"></ion-backdrop>
        <ion-progress-bar id="progress-bar-export" type="indeterminate" color="corretor-green-primary"></ion-progress-bar>
        <ion-spinner id="spinnerExport" name="lines"></ion-spinner>
        <p id="textExport">Aguarde, estamos gerando o arquivo!</p>
      `
      )
      .show('slow');
  }

  public stopProgressBarExport() {
    $('body #textExport').remove();
    $('body #spinnerExport').remove();
    $('body #progress-bar-export').remove();
    $('body #backdropExport').remove();
  }
}
