/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { ToastService } from 'src/app/services/useful/toast/toast.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import * as $ from 'jquery';
import * as print from 'print-js';

@Component({
  selector: 'app-modal-boleto',
  templateUrl: './modal-boleto.page.html',
  styleUrls: ['./modal-boleto.page.scss'],
})
export class ModalBoletoPage {
  @Input('boleto') boleto;

  constructor(
    private route: ActivatedRoute,
    public modalController: ModalController,
    private socialSharing: SocialSharing,
    private clipboard: Clipboard,
    private toast: ToastService,
    private platform: Platform,
    private transfer: FileTransfer,
    private file: File,
    public fileOpener: FileOpener
  ) {}

  ionViewWillEnter() {
    if ($(window).innerWidth() > 767) {
      $('#shared').remove();
    }
    if ($(window).innerWidth() < 768) {
      if (this.platform.is('mobileweb')) {
        $('#shared').remove();
      } else {
        $('#sharedWeb').remove();
      }
    }
    console.log(this.boleto);
  }

  GoClose() {
    this.modalController.dismiss({
      // dismissed: ev
    });
  }

  FormatValor(val) {
    if (val) {
      return val.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    } else {
      return 'não identificado';
    }
  }

  FormatVencimento(val) {
    const indexOF = val.indexOf(' ');
    const date = val.slice(0, indexOF);
    return date.replace(/[/]/g, '.');
  }

  GoDownload(link) {
    if (this.platform.is('android') || this.platform.is('ios')) {
      const url = link;
      const fileTransfer: FileTransferObject = this.transfer.create();
      fileTransfer.download(url, this.file.dataDirectory + 'Boleto.pdf').then(
        (entry) => {
          console.log('download complete: ' + entry.toURL());
          this.fileOpener
            .open(entry.toURL(), 'application/pdf')
            .then(() => console.log('File is opened'))
            .catch((e) => console.log('Error openening file'));
        },
        (error) => {
          // handle error
          console.log(error);
        }
      );
    } else {
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = link;
        save.target = '_blank';
        save.download = 'Boleto' || 'unknown';

        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        const wwindow = window.open(link, '_blank');
        wwindow.document.close();
        wwindow.document.execCommand('SaveAs', true, 'IR' || link);
        wwindow.close();
      }
    }
  }

  GoPrint(link) {
    print(link);
  }

  GoShare(link) {
    this.socialSharing
      .share(
        `Compartilhe seu boleto com o aplicativo desejado`,
        null,
        null,
        link
      )
      // .shareViaWhatsApp(
      //   `Compartilhe seu boleto`,
      //   null,
      //   link
      // )
      .then(() => {})
      .catch(() => {});
  }

  GoShareWhatsApp(link) {
    window.open(
      `https://api.whatsapp.com/send?text=Acesse o Boleto: - ${link}`,
      '_blank'
    );
  }

  GoCopy() {
    if (
      this.boleto.codigoBarra !== '' &&
      this.boleto.codigoBarra !== null &&
      this.boleto.codigoBarra !== undefined
    ) {
      const text = $('#code').text();
      if (this.platform.is('android') || this.platform.is('ios')) {
        this.clipboard.copy(text);
        this.toast.presentToastCopy();
      } else {
        if (navigator.clipboard && window.isSecureContext) {
          navigator.clipboard.writeText(text).then(
            () => {
              this.toast.presentToastCopy();
            },
            (err) => {}
          );
        } else {
          // text area method
          const textArea = document.createElement('textarea');
          textArea.value = text;
          // make the textarea out of viewport
          textArea.style.position = 'fixed';
          textArea.style.left = '-999999px';
          textArea.style.top = '-999999px';
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          this.createCopy(textArea, text);
        }
      }
    }
  }

  createCopy(textArea, text) {
    this.execCopy(textArea, text)
      .then(() => console.log('text copied !'))
      .catch(() => console.log('error'));
  }

  execCopy(textArea, text) {
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}
