import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApoioVendasService {

  public user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));

  public httpOptions = {
    headers: new HttpHeaders({
      'x-access-token': '',
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) { }

  // init files

  GetFiles(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}file`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetFile(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}file/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  // end files

  // init operators

  GetOperator(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}operator/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  // end operator'

  // init document

  GetDocument(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}document/${id}`,
        this.httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  // end document
}
