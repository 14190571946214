import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommissionsService {

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) { }


  GetCommissions(): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const doc = user.taxvat;
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/commission/document/${doc}`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCommissionsAllSearch(dados): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let Data = {
      documento: user.taxvat,
      dataInicio: dados.dataInicio,
      dataFim: dados.dataFim
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/commissions/commission/document/search`,
        Data,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCommissionsAllSearchName(nome): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let Data = {
      documento: user.taxvat,
      nome_contrato: nome,
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/commissions/commission/document/name`,
        Data,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetModalidades(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/modalidade/all`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSubLotesCommissions(): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const doc = user.taxvat;
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/sub/document/${doc}`,
        // `${environment.SERVER_ADDRESS}corretora/commissions/sub/document/13097063000150`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetSubLotesCommissionsSearch(dados): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let Data = {
      documento: user.taxvat,
      situacao_ID: dados.situacao_ID,
      dataInicio: dados.dataInicio,
      dataFim: dados.dataFim
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/commissions/sub/search`,
        Data,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetStatusNF(): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/situacoes/all`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCommissionSubLotesCommissions(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/sub/${id}/commission`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetEmpresa(id): Observable<any> {
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}corretora/commissions/empresa/${id}`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetVersion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`${environment.SERVER_ADDRESS}version/app/corretor`)
      .pipe(tap(async (res: any) => { }));
  }

  UploadNFSubLote(file, id, valorLiquido, dataEmissao, numeroNF) {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': user.corretora.accessToken,
      }),
    };
    let formData = new FormData();
    formData.append('file', file.arquivo);
    formData.append('valorLiquido', valorLiquido);
    formData.append('dataEmissao', dataEmissao);
    formData.append('numeroNF', numeroNF);
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}corretora/commissions/nf/${id}`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }
}
